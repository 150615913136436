import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import neptImg from "../Assets/images/products/aqua/product-1.JPG";
import geoImg from "../Assets/images/products/aqua/product-2.jpg";
import doEnImg from "../Assets/images/products/aqua/product-3.jpg";
import immunoImg from "../Assets/images/products/aqua/product-4.jpg";
import { fadeIn } from "./Variants";
import shrimpPointers from "../Assets/logo/shrimp.png";

const Products = () => {
  const [visibleSection, setVisibleSection] = useState("section1");

  const toggleVisibility = (section) => {
    setVisibleSection(section);
  };

  return (
    <section className="" id="products">
      <div>
        <div className="mt-12">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            className="hidden md:flex absolute bg-[#41ADEE] p-52 -mt-7 rounded-r-3xl"
          ></motion.div>
          <div className="flex flex-wrap items-center mt-6 md:text-left text-center p-2 ">
            <motion.div
              variants={fadeIn("right", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.9 }}
              className="w-full md:w-3/5 lg:w-1/2"
            >
              <img
                src={immunoImg}
                alt="img"
                className="relative md:ml-10 h-[340px] w-[600px] rounded-2xl inline-block shadow-lg"
              />
            </motion.div>
            <motion.div
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              className="w-full md:w-2/5 lg:w-1/2 text-center md:text-left lg:pl-14"
            >
              <h3 className="font-extrabold text-green-800 mt-2 md:mt-6 text-2xl sm:text-2xl">
                NEPTUNE–WATER PROBIOTIC
              </h3>
              <p className="text-md md:text-xl mt-1 text-[#718096] md:mt-4">
                Water probiotic is to improve the water quality in Aquaculture
                farming is the application of probiotic micro-organism to the
                pond.
              </p>
              <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                Benefits
              </h3>
              <ul className="mt-2 text-md">
                <li className="mt-2">
                  <img src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Reduces the level of Ammonia and Nitrite in water
                </li>
                <li className="mt-2">
                  <img src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Maintains the optimum pH level in water
                </li>
                <li className="mt-2">
                  <img src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Helps in stress tolerance
                </li>
                <li className="mt-2">
                  <img src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Reduces the organic residuals
                </li>
                <li className="mt-2">
                  <img src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Improves overall water quality
                </li>
              </ul>
            </motion.div>
          </div>
        </div>

        <div className="md:p-12">
          <motion.div
            variants={fadeIn("left", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            className="hidden md:flex absolute bg-[#41ADEE] p-52 -mt-2 rounded-l-3xl right-0"
          ></motion.div>
          <div className="flex flex-wrap items-center mt-6  rounded-lg text-left text-center">
            <motion.div
              variants={fadeIn("left", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.9 }}
              className="w-full md:w-3/5 lg:w-1/2"
            >
              <img
                src={neptImg}
                alt="light"
                class="relative h-[340px] w-[600px] inline-block rounded-2xl shadow-lg"
              />
            </motion.div>
            <motion.div
              variants={fadeIn("up", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              class="w-full md:w-2/5 lg:w-1/2 md:order-first text-center md:text-left lg:pr-14"
            >
              <h3 class="text-green-800 font-bold mt-2 md:mt-6 text-2xl sm:text-2xl">
              GEO–SOIL PROBIOTIC
              </h3>
              <p class="text-md md:text-xl mt-1 text-[#718096] md:mt-4">
              Contains Bio Enzymes, Bacillus subtilis and Naturals Extracts. The probiotic used in aquaculture is a live microbial addition, supplied through feed which yields beneficial effect by modifying the gut microflora by enhancing feed absorption, nutrition and immunity against the pathogenic bacteria in the gut.
              </p>
              <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">Benefits</h3>
              <ul className="mt-2">
                <li className="mt-2"><img src={shrimpPointers} alt="" className="inline h-5" />{" "}Hydrogen sulphide reduction</li>
                <li className="mt-2"><img src={shrimpPointers} alt="" className="inline h-5" />{" "}Support to improve the health of Aqua culture Species</li>
                <li className="mt-2"><img src={shrimpPointers} alt="" className="inline h-5" />{" "}Organic matter degradation</li>
              </ul>
            </motion.div>
          </div>
        </div>


        <div className="mt-6">
          <motion.div
            variants={fadeIn("right", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            className="hidden md:flex absolute bg-[#41ADEE] p-52 -mt-7 rounded-r-3xl"
          ></motion.div>

          <div className="flex flex-wrap items-center mt-6 text-left text-center ">
            <motion.div
              variants={fadeIn("right", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.9 }}
              className="w-full md:w-3/5 lg:w-1/2"
            >
              <img
                src={doEnImg}
                alt="img"
                className="relative md:ml-10 h-[340px] w-[600px] rounded-2xl inline-block shadow-lg"
              />
            </motion.div>
            <motion.div
              variants={fadeIn("up", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              className="w-full md:w-2/5 lg:w-1/2 text-center md:text-left lg:pl-14"
            >
              <h3 className="font-bold text-green-800 mt-2 md:mt-6 text-2xl sm:text-2xl">
              HZ-DO</h3>
              <p className="text-md  md:text-xl mt-1 text-[#718096] md:mt-4">The success of aquaculture operations hinges on maintaining optimal pond water and soil quality, with dissolved oxygen (DO) being crucial. Prawns spend most of their time at the pond bottom, so low DO levels can cause stunted growth, higher disease susceptibility, malnutrition, and mortality. A DO enhancer can improve DO levels in aquaculture ponds.
              </p>
              <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">Benefits</h3>
              <ul className="mt-2 text-md">
                <li className="mt-2"><img src={shrimpPointers} alt="" className="inline h-5" />{" "}To over come the situation of lowered DO levels in the pond</li>
                <li className="mt-2"><img src={shrimpPointers} alt="" className="inline h-5" />{" "}To controlling the over growth of anaerobic bacteria</li>
                <li className="mt-2"><img src={shrimpPointers} alt="" className="inline h-5" />{" "}To provide sufficient, stable and sustainable generation of oxygen</li>
              </ul>
            </motion.div>
          </div>
        </div>

        <div className="md:p-12">
          <motion.div
            variants={fadeIn("left", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            className="hidden md:flex absolute bg-[#41ADEE] p-52 -mt-2 rounded-l-3xl right-0"
          ></motion.div>
          <div className="flex flex-wrap items-center mt-6 p-2 rounded-lg text-left text-center">
            <motion.div
              variants={fadeIn("left", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.9 }}
              className="w-full md:w-3/5 lg:w-1/2 "
            >
              <img
                src={geoImg}
                alt="light"
                class="relative h-[340px] w-[600px] inline-block rounded-2xl shadow-lg"
              />
            </motion.div>
            <motion.div
              variants={fadeIn("up", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              class="w-full md:w-2/5 lg:w-1/2 md:order-first text-center md:text-left lg:pr-14"
            >
              <h3 class="text-green-800 font-bold mt-2 md:mt-6 text-2xl sm:text-2xl">
              NEUTRO</h3>
              <p class="text-md md:text-xl mt-1 text-[#718096] md:mt-4">
              Enzyme supplementation in Aquaculture nutrition reduces feeding costs, allows flexibility on diets formulation, enhances performance and litter quality, improves health, and reduces total production costs
              </p>
              <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">Benefits</h3>
              <ul className="mt-2">
                <li className="mt-2"><img src={shrimpPointers} alt="" className="inline h-5" />{" "}To increase feed intake, weight gain, and Feed: Gain ratio</li>
                <li className="mt-2"><img src={shrimpPointers} alt="" className="inline h-5" />{" "}To reduce beak impaction and vent plugging, to alter population of gut microorganisms</li>
                <li className="mt-2"><img src={shrimpPointers} alt="" className="inline h-5" />{" "}To lower water intake</li>
              </ul>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
