import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import icon1 from '../Assets/icons/microscope.gif';
import icon2 from '../Assets/icons/global.gif';
import icon3 from '../Assets/icons/computer.gif';
import icon4 from '../Assets/icons/online-support.gif';

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const textVariants = {
  hidden: { opacity: 0, y: -30 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

// Card data
const cardData = [
  {
    title: 'Technological Advancements',
    description: 'We focus on cutting-edge R&D to deliver breakthrough solutions in aquaculture, agriculture, and animal care.',
    icon: icon1,
  },
  {
    title: 'Global Affiliations',
    description: 'We collaborate with top specialists in construction, aquaculture, and poultry to ensure the best solutions.',
    icon: icon2,
  },
  {
    title: 'Melding Technology',
    description: 'Integrating advanced technology with responsibly trained staff to offer top-quality technical support.',
    icon: icon3,
  },
  {
    title: 'Technical Support',
    description: 'Comprehensive technical service support is a key component of our solutions bouquet, ensuring success.',
    icon: icon4,
  },
];

const WhyUs = () => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <section id="why-us" className="bg-[#e7e5e5] py-12">
      <div ref={ref} className="-mt-20 mx-auto sm:max-w-[80%] md:max-w-[1300px] grid grid-cols-1 md:grid-cols-2  bg-white rounded-2xl p-6 shadow-xl">
        <div className="text-center flex justify-center items-center mb-8">
          <motion.div
            className="flex flex-col justify-center items-center w-4/5 mx-auto"
            variants={textVariants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
          >
            <h2 className="text-4xl font-bold text-[#4aa552] mb-4">Why Choose Us</h2>
            <p className="text-lg text-gray-800 mb-6">
              We help minimize the use of natural resources using Herbozone Lab Products. Revitalizing aquaculture ponds, agricultural lands, and ensuring animal health through sustainable inputs for soil, water, and animals.
            </p>
          </motion.div>
        </div>

        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 gap-4"
          variants={containerVariants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
        >
          {cardData.map((card, index) => (
            <motion.div
              key={index}
              className="rounded-xl relative flex flex-col"
              variants={cardVariants}
            >
              <div className='border rounded-xl p-4 text-center flex flex-col items-center gap-2 h-[240px] md:h-[280px] shadow-md'>
                <img src={card.icon} alt={card.title} className='h-14 w-14' />
                <h1 className='font-bold text-xl'>{card.title}</h1>
                <p>{card.description}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default WhyUs;
