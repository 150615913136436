import React from 'react';
import { motion } from 'framer-motion'
import { fadeIn } from "./Variants";
import shrimpPointers from "../Assets/logo/rice.svg";
import img1 from '../Assets/images/products/agri/img1.jpg'
import img2 from '../Assets/images/products/agri/img2.jpg'
import img3 from '../Assets/images/products/agri/img3.jpg'

const Agriprod = () => {
    return (
        <section>
            <div className="mt-12">
                <motion.div
                    variants={fadeIn("right", 0.3)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: true, amount: 0.7 }}
                    className="hidden md:flex absolute bg-[#41ADEE] p-52 -mt-7 rounded-r-3xl"
                ></motion.div>
                <div className="flex flex-wrap items-center  md:text-left text-center ">
                    <motion.div
                        variants={fadeIn("right", 0.5)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: true, amount: 0.9 }}
                        className="w-full md:w-3/5 lg:w-1/2"
                    >
                        <img
                            src={img1}
                            alt="NPK"
                            className="relative md:ml-10 h-[340px] w-[600px] rounded-2xl inline-block shadow-lg"
                        />
                    </motion.div>
                    <motion.div
                        variants={fadeIn("up", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: true, amount: 0.7 }}
                        className="w-full md:w-2/5 lg:w-1/2 text-center md:text-left lg:pl-14"
                    >
                        <h3 className="font-extrabold text-green-800 mt-2 md:mt-6 text-2xl sm:text-2xl">NPK Bio Fertilizer
                        </h3>
                        <p className="text-md md:text-xl mt-1 text-[#718096] md:mt-4">NPK is a microbial formulation that synthesizes atmospheric nitrogen, solubilizes phosphate, and mobilizes potash into an available form, thereby supplementing balanced nutrition to plants. It is liquid, easy to apply, and highly efficient.
                        </p>
                        <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">Advantages:
                        </h3>
                        <ul className="mt-2 text-md">
                            <li className="mt-2">
                                <img src={shrimpPointers} alt="" className="inline h-5" />{" "}
                                Provides NPK (4:5:6) and helpful micronutrients to plants.
                            </li>
                            <li className="mt-2">
                                <img src={shrimpPointers} alt="" className="inline h-5" />{" "}
                                Increases use of atmospheric nitrogen.
                            </li>
                            <li className="mt-2">
                                <img src={shrimpPointers} alt="" className="inline h-5" />{" "}
                                Solubilizes phosphate and mobilizes potash
                            </li>
                            <li className="mt-2">
                                <img src={shrimpPointers} alt="" className="inline h-5" />{" "}
                                Improves water-holding capacity and reduces soil hardness
                            </li>
                            <li className="mt-2">
                                <img src={shrimpPointers} alt="" className="inline h-5" />{" "}
                                Non-toxic, cost-effective, and improves soil quality and plant growth.
                            </li>
                        </ul>
                    </motion.div>
                </div>
            </div>

            <div className="md:p-12">
                <motion.div
                    variants={fadeIn("left", 0.3)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: true, amount: 0.7 }}
                    className="hidden md:flex absolute bg-[#41ADEE] p-52 -mt-2 rounded-l-3xl right-0"
                ></motion.div>
                <div className="flex flex-wrap items-center mt-6 p-2 rounded-lg text-left text-center">
                    <motion.div
                        variants={fadeIn("left", 0.5)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: true, amount: 0.9 }}
                        className="w-full md:w-3/5 lg:w-1/2"
                    >
                        <img
                            src={img2}
                            alt="PGR"
                            class="relative h-[340px] w-[600px] inline-block rounded-2xl shadow-lg"
                        />
                    </motion.div>
                    <motion.div
                        variants={fadeIn("up", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: true, amount: 0.7 }}
                        class="w-full md:w-2/5 lg:w-1/2 md:order-first text-center md:text-left lg:pr-14"
                    >
                        <h3 class="text-green-800 font-bold mt-2 md:mt-6 text-2xl sm:text-2xl">
                            Venus Stim Plant Growth Regulator
                        </h3>
                        <p class="text-md md:text-xl mt-1 text-[#718096] md:mt-4">
                            Venus stim is a concentrated aqueous mixture of humic acid and fulvic acid, suitable for soil and foliar application. It mobilizes macro and micronutrients, improving their availability to plants.              </p>
                        <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">Benefits</h3>
                        <ul className="mt-2">
                            <li className="mt-2"><img src={shrimpPointers} alt="" className="inline h-5" />{" "}Enhances nutrient availability and uptake.</li>
                            <li className="mt-2"><img src={shrimpPointers} alt="" className="inline h-5" />{" "}Easily blends with pesticides, reducing application costs</li>
                            <li className="mt-2"><img src={shrimpPointers} alt="" className="inline h-5" />{" "}Improves soil and plant metabolism, leading to healthier plants.</li>
                        </ul>
                    </motion.div>
                </div>
            </div>


            <div className="mt-12 mb-24">
                <motion.div
                    variants={fadeIn("right", 0.3)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: true, amount: 0.7 }}
                    className="hidden md:flex absolute bg-[#41ADEE] p-52 -mt-7 rounded-r-3xl"
                ></motion.div>
                <div className="flex flex-wrap items-center mt-6 md:text-left text-center p-2 ">
                    <motion.div
                        variants={fadeIn("right", 0.5)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: true, amount: 0.9 }}
                        className="w-full md:w-3/5 lg:w-1/2"
                    >
                        <img
                            src={img3}
                            alt="PGR"
                            className="relative md:ml-10 h-[340px] w-[600px] rounded-2xl inline-block shadow-lg"
                        />
                    </motion.div>
                    <motion.div
                        variants={fadeIn("up", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: true, amount: 0.7 }}
                        className="w-full md:w-2/5 lg:w-1/2 text-center md:text-left lg:pl-14"
                    >
                        <h3 className="font-extrabold text-green-800 mt-2 md:mt-6 text-2xl sm:text-2xl">PGR Plant Growth Regulator
                        </h3>
                        <p className="text-md md:text-xl mt-1 text-[#718096] md:mt-4">PGR promotes growth and yield of crops by improving the plant’s natural metabolic processes, ensuring more productive outcomes.
                        </p>
                        <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">Advantages:
                        </h3>
                        <ul className="mt-2 text-md">
                            <li className="mt-2">
                                <img src={shrimpPointers} alt="" className="inline h-5" />{" "}
                                Enhances plant growth and yields.
                            </li>
                            <li className="mt-2">
                                <img src={shrimpPointers} alt="" className="inline h-5" />{" "}
                                Improves resistance to environmental stressors.
                            </li>
                            <li className="mt-2">
                                <img src={shrimpPointers} alt="" className="inline h-5" />{" "}
                                Supports healthier root and shoot growth.
                            </li>
                        </ul>
                    </motion.div>
                </div>
            </div>

        </section>
    );
};

export default Agriprod;
