import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import researchImg from '../Assets/images/whatwedo/research.jpg';
import img1 from '../Assets/images/whatwedo/img1.jpg'
import img2 from '../Assets/images/whatwedo/img2.jpg'
import img3 from '../Assets/images/whatwedo/img3.jpeg'
import img4 from '../Assets/images/whatwedo/img4.jpg'

const cardsData = [
  {
    id: 1,
    title: 'Research & Development',
    description:
      'Our team of scientists, biotechnologists, and agronomists work diligently to discover bioenzyme-based solutions that address specific challenges in agriculture and aquaculture.',
    imageSrc: researchImg,
  },
  {
    id: 2,
    title: 'Product Development',
    description:
      'We take our discoveries to the next level by refining bioenzyme formulations and ensuring their efficacy through extensive testing.',
    imageSrc: img4,
  },
  {
    id: 3,
    title: 'Customized Solutions',
    description:
      'Our team collaborates with farmers and aquaculturists to tailor solutions that meet their specific needs, enhancing their practices.',
    imageSrc: img1,
  },
  {
    id: 4,
    title: 'Education & Outreach',
    description:
      'We provide training and resources to help farmers integrate bioenzyme solutions into their practices, promoting sustainable growth.',
    imageSrc: img2,
  },
  {
    id: 5,
    title: 'Monitoring & Support',
    description:
      'Continuous support and technical assistance ensure that our solutions remain effective, helping farmers achieve long-term success.',
    imageSrc: img3,
  },
];

const WhatWeDo = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 3, 
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: null,
    prevArrow: null,  
    responsive: [
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1, 
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 2, 
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className='bg-[#e7e5e5] py-16'>
      <div className='text-center mb-0 md:mb-20'>
        <h1 className='text-4xl font-bold text-[#4BA652] m-4'>What We Do?</h1>
      </div>
      <div className='relative max-w-[1240px] mx-auto hidden md:block'>
        <Slider {...settings} >
          {cardsData.map((card) => (
            <div className='h-[480px] text-white rounded-xl' key={card.id}>
              <div
                className='h-full rounded-xl bg-cover bg-center'
                style={{
                  backgroundImage: `url(${card.imageSrc})`,
                }}
              >
                <div className='text-center flex flex-col justify-center items-center gap-1 p-4 rounded-xl'>
                  <div className='bg-cards bg-opacity-10 mt-[300px] md:mt-[280px] rounded-xl p-1'>
                    <p className='text-xl font-bold text-black'>{card.title}</p>
                    <p className='text-gray-700 font-semibold'>{card.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {/* mobile view */}
      <div className='relative flex flex-col gap-2 justify-center items-center md:hidden'>
      {cardsData.map((card) => (
            <div className='h-[320px] text-white rounded-xl' key={card.id}>
              <div
                className='h-full rounded-xl bg-cover w-[400px] bg-center'
                style={{
                  backgroundImage: `url(${card.imageSrc})`,
                }}
              >
                <div className='text-center flex flex-col justify-center items-end gap-1 p-4 rounded-xl '>
                  <div className='bg-cards bg-opacity-10 rounded-xl p-1 mt-20'>
                    <p className='text-xl font-bold text-black'>{card.title}</p>
                    <p className='text-gray-700 font-semibold'>{card.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};

export default WhatWeDo;
