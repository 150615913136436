import React from "react";
import Navbar from './Components/Navbar'
import About from "./Components/About";
import Contact from "./Components/Contact";
import WhatWeDo from "./Components/WhatWeDo";
import WhyUs from "./Components/WhyUs";
import ProductTabs from "./Components/ProductTabs";
import HeroSection from "./Components/HeroSection";

function App() {
  return (
    <div >
      <Navbar/>
      <HeroSection/>
      <About/>
      <WhatWeDo/>
      <WhyUs/>
      <ProductTabs/>
      <Contact/>
    </div>
  );
}

export default App;
