import React, { useState, useRef, useEffect } from 'react';
import AquaProducts from './AquaProducts';
import AnimalCareProducts from './AnimalCareProducts';
import AgriProducts from './AgriProducts';

const ProductTabs = () => {
  const [openTab, setOpenTab] = useState(1);
  const aquaRef = useRef(null);
  const agriRef = useRef(null);
  const animalCareRef = useRef(null);

  const handleTabClick = (tabIndex) => {
    setOpenTab(tabIndex);
  };

  useEffect(() => {
    if (aquaRef.current) aquaRef.current.scrollTop = 0;
    if (agriRef.current) agriRef.current.scrollTop = 0;
    if (animalCareRef.current) animalCareRef.current.scrollTop = 0;
  }, [openTab]);

  return (
    <div className="flex items-center justify-center" id="products">
      <div className="">
        <div className="text-center py-8">
          <h1 className="text-4xl font-bold text-[#4BA652]">Products</h1>
        </div>
        <div className="">
          <div className="mb-4 flex flex-col md:flex-row shadow-lg sticky top-16 z-10">
            <button
              onClick={() => handleTabClick(1)}
              className={`flex-1 py-4 text-md md:text-xl focus:outline-none transition-all duration-300 transform ${
                openTab === 1
                  ? 'bg-[#41ADEE] text-white'
                  : 'bg-[#4ba652] text-white scale-100'
              }`}
            >
              Aquaculture Products
            </button>
            <button
              onClick={() => handleTabClick(2)}
              className={`flex-1 py-4 text-md md:text-xl focus:outline-none transition-all duration-300 transform ${
                openTab === 2
                  ? 'bg-[#41ADEE] text-white'
                  : 'bg-[#4ba652] text-white scale-100'
              }`}
            >
              Agriculture Products
            </button>
            <button
              onClick={() => handleTabClick(3)}
              className={`flex-1 py-4 text-md md:text-xl focus:outline-none transition-all duration-300 transform ${
                openTab === 3
                  ? 'bg-[#41ADEE] text-white'
                  : 'bg-[#4ba652] text-white scale-100'
              }`}
            >
              Animal Care
            </button>
          </div>

          {/* Tab Content */}
          {openTab === 1 && (
            <div
              ref={aquaRef}
              className="transition-all duration-300  overflow-auto"
              id="aquaculture"
            >
              <AquaProducts />
            </div>
          )}

          {openTab === 2 && (
            <div
              ref={agriRef}
              className="transition-all duration-300 overflow-auto"
              id="agriculture"
            >
              <AgriProducts />
            </div>
          )}

          {openTab === 3 && (
            <div
              ref={animalCareRef}
              className="transition-all duration-300 overflow-auto"
              id="animalcare"
            >
              <AnimalCareProducts />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductTabs;
