import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { ReactTyped } from 'react-typed'; 
import aquacultureImg from "../Assets/images/home/aqua.jpg";
import agricultureImg from "../Assets/images/home/agri.jpg";
import animalCareImg from "../Assets/images/home/farm.jpg";

const topics = [
  { name: "Aquaculture", img: aquacultureImg, color: 'text-blue-600' },
  { name: "Agriculture", img: agricultureImg, color: 'text-green-600' },
  { name: "Animal Care", img: animalCareImg, color: '' },  
];

const HeroSection = () => {
  const [currentTopicIndex, setCurrentTopicIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTopicIndex((prevIndex) => (prevIndex + 1) % topics.length);
    }, 3000); 
    return () => clearInterval(interval);
  }, []);

  const renderTypedText = (text, color) => (
    <ReactTyped
      strings={[text]}
      typeSpeed={50}
      backSpeed={20}
      loop={false}
      showCursor={false}  
      className={`text-3xl md:text-7xl font-bold ${color}`}  
    />
  );

  const renderTopicName = () => {
    const currentTopic = topics[currentTopicIndex];

    if (currentTopic.name === "Animal Care") {
      return (
        <h2 className="text-3xl md:text-7xl font-bold">
          {renderTypedText("Animal", "text-blue-600")}{" "} 
          {renderTypedText("Care", "text-green-600")}      
        </h2>
      );
    }

    // For other topics, use ReactTyped for typing effect
    return renderTypedText(currentTopic.name, currentTopic.color);
  };

  return (
    <section>
      <div
        className="relative h-screen w-full flex justify-center md:justify-normal items-center bg-cover bg-center transition-all duration-1000 md:flex"
        style={{ backgroundImage: `url(${topics[currentTopicIndex].img})` }}
      >
        <motion.div
          className="relative z-10 py-10 px-8 w-[80%] md:w-[60%] bg-home md:mt-[516px] rounded-xl md:rounded-tr-full flex flex-col items-center"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, delay: 0.5 }}
        >
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
            className="text-2xl md:text-3xl text-gray-900 text-center md:text-left"
          >
            Revolutionizing sectors through <br /> sustainable practices in
          </motion.p>

          <motion.div
            key={currentTopicIndex}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.6 }}
            className="text-xl mt-4 min-h-[80px]" 
          >
            {renderTopicName()}
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default HeroSection;
