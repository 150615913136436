import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { fadeIn } from "./Variants";
import animalPointer from "../Assets/logo/pawprint.png";
import img1 from '../Assets/images/products/animal/img1.jpg';
import img2 from '../Assets/images/products/animal/img2.jpg';

const AnimalCareProducts = () => {
  const [visibleSection, setVisibleSection] = useState("section1");

  return (
    <section className="" id="animal-care-products">
      <div>
        <div className="mt-12">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            className="hidden md:flex absolute bg-[#41ADEE] p-52  rounded-r-3xl"
          ></motion.div>
          <div className="flex flex-wrap items-center mt-6 md:text-left text-center p-2 ">
            <motion.div
              variants={fadeIn("right", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.9 }}
              className="w-full md:w-3/5 lg:w-1/2"
            >
              <img
                src={img1}
                alt="Immunizer"
                className="relative md:ml-10 h-[340px] w-[600px] rounded-2xl inline-block shadow-lg"
              />
            </motion.div>
            <motion.div
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              className="w-full md:w-2/5 lg:w-1/2 text-center md:text-left lg:pl-14"
            >
              <h3 className="font-extrabold text-green-800 mt-2 md:mt-6 text-2xl sm:text-2xl">
                Immunizer
              </h3>
              <p className="text-md md:text-xl mt-1 text-[#718096] md:mt-4">
                Immunizer is a Enzyme and vitamin consortium product, Enzyme supplementation in poultry nutrition reduces feeding costs, allows flexibility on diets formulation, enhances performance and litter quality, improves poultry health, and reduces total production costs.
              </p>
              <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">Benefits</h3>
              <ul className="mt-2 text-md">
                <li className="mt-2">
                  <img src={animalPointer} alt="" className="inline h-5" /> improve nutrient digestibility and growth performance
                </li>
                <li className="mt-2">
                  <img src={animalPointer} alt="" className="inline h-5" />  It is added to soybean diet then improves weight gain and Feed: Gain ratio in broilers
                </li>
                <li className="mt-2">
                  <img src={animalPointer} alt="" className="inline h-5" /> Enzymes reduce viscosity, enhance gut transit rate.
                </li>
                <li className="mt-2">
                  <img src={animalPointer} alt="" className="inline h-5" /> decrease water amount in faeces, and provide a better litter quality
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
        <div className="md:p-12">
          <motion.div
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            className="hidden md:flex absolute bg-[#41ADEE] p-52  rounded-l-3xl right-0"
          ></motion.div>
          <div className="flex flex-wrap items-center -mt-6 p-2 rounded-lg text-left text-center">
            <motion.div
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.9 }}
              className="w-full md:w-3/5 lg:w-1/2"
            >
              <img
                src={img2}
                alt="Proteinizer"
                class="relative h-[340px] w-[600px] inline-block rounded-2xl shadow-lg"
              />
            </motion.div>
            <motion.div
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              class="w-full md:w-2/5 lg:w-1/2 md:order-first text-center md:text-left lg:pr-14"
            >
              <h3 class="text-green-800 font-bold mt-2 md:mt-6 text-2xl sm:text-2xl">Proteinizer
              </h3>
              <p class="text-md md:text-xl mt-1 text-[#718096] md:mt-4">
                Proteins that are involved in all anabolic and catabolic pathways of digestion and metabolism. Exogenous enzymes are administered in poultry diets to make diet formulation more flexible
              </p>
              <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">Benefits</h3>
              <ul className="mt-2 text-md">
                <li className="mt-2"><img src={animalPointer} alt="" className="inline h-5" />{" "}To reduce production cost</li>
                <li className="mt-2"><img src={animalPointer} alt="" className="inline h-5" />{" "}decrease digest a viscosity, to enhance nutrients digestion</li>
                <li className="mt-2"><img src={animalPointer} alt="" className="inline h-5" />{" "}To improve Apparent Metabolizable Energy value of the diet, to increase feed intake, weight gain.</li>
                <li className="mt-2"><img src={animalPointer} alt="" className="inline h-5" />{" "}To reduce beak impaction and vent plugging</li>
                <li className="mt-2"><img src={animalPointer} alt="" className="inline h-5" />{" "}To alter population of gut microorganisms, to lower water intake, water content of excreta</li>
                <li className="mt-2"><img src={animalPointer} alt="" className="inline h-5" />{" "}To improve the nutritional value of poultry diets and the subsequent performance</li>
              </ul>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AnimalCareProducts;
