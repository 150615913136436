import React, { useState, useEffect } from 'react';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import mainLogo from '../Assets/logo/Herbozone.png';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); 
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false); 

  const handleNav = () => {
    setNav(!nav);
  };

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    };
  };

  const handleNavItemClick = () => {
    setNav(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className="fixed top-0 w-full z-40 transition-colors duration-300 bg-white">
      <div className="flex justify-between items-center h-16 max-w-[1240px] mx-auto px-4">
        <div className="flex cursor-pointer">
          <a href="#">
            <img src={mainLogo} alt="Herbozone Logo" className="w-[190px]" />
          </a>
        </div>

        {/* Desktop Menu */}
        <ul className="hidden md:flex text-lg font-semibold cursor-pointer text-[#1D2A4D]">
          <li className="p-4 px-4 hover:text-[#4BA652]">
            <a href="#" onClick={handleNavItemClick}>Home</a>
          </li>
          <li className="p-4 px-4 hover:text-[#4BA652]">
            <a href="#about" onClick={handleNavItemClick}>About</a>
          </li>
          {/* Products with Submenu */}
          <li
            className="relative p-4 px-4 hover:text-[#4BA652]"
            onMouseEnter={() => setIsDropdownOpen(true)}
            // onMouseLeave={() => setIsDropdownOpen(false)}
          >
            <div className="flex items-center">
              <a href="#products">Products</a>
            </div>
            {/* Submenu for Desktop */}
            {isDropdownOpen && (
              <ul
                className="absolute top-full left-0 mt-2 bg-white shadow-lg text-[#1D2A4D] w-48"
                onMouseEnter={() => setIsDropdownOpen(true)} 
                onMouseLeave={() => setIsDropdownOpen(false)} 
              >
                <li className="px-4 py-2 hover:bg-gray-100">
                  <a href="#aquaculture">Aquaculture</a>
                </li>
                <li className="px-4 py-2 hover:bg-gray-100">
                  <a href="#agriculture">Agriculture</a>
                </li>
                <li className="px-4 py-2 hover:bg-gray-100">
                  <a href="#animalcare">Animal Care</a>
                </li>
              </ul>
            )}
          </li>
          <li className="p-4 px-4 hover:text-[#4BA652]">
            <a href="#contact" onClick={handleNavItemClick}>Contact Us</a>
          </li>
        </ul>

        {/* Mobile Menu Toggle Button */}
        <div onClick={handleNav} className="block md:hidden cursor-pointer mr-2">
          {nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
        </div>

        {/* Mobile Menu */}
        <div
          className={`${
            nav ? 'left-0' : '-left-full'
          } md:hidden fixed top-0 w-[60%] h-full bg-[#1D2A4D] text-[#4BA652] p-8 transition-all duration-500 z-50`}
        >
          <ul className="space-y-6 text-lg font-bold cursor-pointer">
            <li>
              <a href="#" onClick={handleNavItemClick}>Home</a>
            </li>
            <li>
              <a href="#about" onClick={handleNavItemClick}>About</a>
            </li>
            <li>
              <div
                className="flex justify-between items-center"
                onClick={() => setIsMobileDropdownOpen(!isMobileDropdownOpen)}
              >
                <a href="#products" onClick={handleNavItemClick}>
                  Products
                </a>
              </div>
              {/* Submenu for Mobile */}
              {isMobileDropdownOpen && (
                <ul className="mt-2 ml-4 text-[#4BA652] space-y-2">
                  <li>
                    <a href="#aquaculture" onClick={handleNavItemClick}>
                      Aquaculture
                    </a>
                  </li>
                  <li>
                    <a href="#agriculture" onClick={handleNavItemClick}>
                      Agriculture
                    </a>
                  </li>
                  <li>
                    <a href="#animalcare" onClick={handleNavItemClick}>
                      Animal Care
                    </a>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <a href="#contact" onClick={handleNavItemClick}>Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
