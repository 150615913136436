import React from 'react';
import { motion } from 'framer-motion';
import abtImg from '../Assets/images/Aqua-farming.webp'

const AboutUs = () => {
  return (
    <section id="about" className="h-screen py-12">
      <div className="max-w-[1240px] mx-auto">
        <motion.div 
          className="text-center mb-32"
          initial={{ opacity: 0, y: 50 }} 
          animate={{ opacity: 1, y: 0 }} 
          transition={{ duration: 1 }}
        >
          <h2 className="text-3xl md:text-4xl font-bold text-[#4BA652]">Who We Are</h2>
          <p className="mt-2 text-md md:text-lg text-gray-600">We are committed to excellence in science and sustainability.</p>
        </motion.div>
        
        <div className="grid md:grid-cols-2 gap-12 justify-center items-center">
          <motion.div
            className="space-y-6 text-md md:text-lg text-gray-700 text-center md:text-left"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <p>
              <strong className="text-[#41ADEE]">Herbozone</strong> confidently brings together a team of accomplished 
              <span className="text-[#4BA652]"> scientists, biotechnologists, and agronomists</span> specializing in 
              <span className="text-[#41ADEE]"> biochemistry, microbiology</span>, and agriculture. With a decade-long proven 
              track record, they have achieved a significant milestone in researching and developing natural regenerative solutions.
            </p>
            <p>
              Their innovative and disruptive product development enhances yields and reduces operating costs across industries, 
              including aquaculture, agriculture, and animal care. With the support of a robust 
              <span className="text-[#4BA652]"> R&D team</span>, Herbozone is driving sustainability forward.
            </p>
          </motion.div>

          <motion.div
            className="flex flex-col gap-4 hidden md:flex"  // Hidden on small screens, visible on medium and up
            initial={{ opacity: 0, scale: 0 }} 
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <div className="relative">
              <motion.img 
                src={abtImg} 
                alt="Scientists working" 
                className="rounded-lg w-full object-cover shadow-lg"
                transition={{ duration: 1 }}
              />
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
